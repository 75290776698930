.widget-short-button {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: 0px 2px 20px 0px #0d0d0e1a;
}

.widget-short-button:disabled {
  opacity: 0.5;
}

@media (min-width: 1400px) and (max-width: 2500px) {
  .widget-panel-margin {
    margin-right: 250px;
    margin-left: 250px;
  }
}

@media (min-width: 1080px) and (max-width: 1400px) {
  .widget-panel-margin {
    margin-right: 160px;
    margin-left: 160px;
  }
}

@media (min-width: 600px) and (max-width: 1080px) {
  .widget-panel-margin {
    margin-right: 120px;
    margin-left: 120px;
  }
}

@media (max-width: 600px) {
  .widget-panel-margin {
    margin-right: 40px;
    margin-left: 40px;
  }
}
