@media (min-width: 1080px) {
  .content-2-columns {
    display: grid;
    grid-template-columns: 0.8fr 3.2fr;
    height: 100%;
    width: 100%;
  }

  .gap20 {
    grid-column-gap: 20px;
  }

  .gap40 {
    grid-column-gap: 40px;
  }
}

@media (min-width: 700px) and (max-width: 1080px) {
  .content-2-columns {
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: 100%;
    width: 100%;
  }

  .gap20 {
    grid-column-gap: 15px;
  }

  .gap40 {
    grid-column-gap: 30px;
  }
}

@media (min-width: 300px) and (max-width: 700px) {
  .content-2-columns {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 100%;
    width: 100%;
  }

  .gap20 {
    grid-column-gap: 10px;
  }

  .gap40 {
    grid-column-gap: 20px;
  }
}
