.sortIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.defaultIcon {
  visibility: hidden;
}

.tableHeading:hover .defaultIcon {
  visibility: visible;
}

.left-shadow {
  @media (min-width: 768px) {
    filter: drop-shadow(6px 6px 4px rgba(13, 13, 14, 0.1));
  }
}

.right-shadow {
  @media (min-width: 768px) {
    filter: drop-shadow(-6px 6px 4px rgba(13, 13, 14, 0.1));
  }
}
