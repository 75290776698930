.jodit-toolbar-button {
  align-items: center;
  border-radius: 3px;
  display: flex;
  height: 34px;
  justify-content: center;
  min-width: 34px;
  overflow: hidden;
  border: none !important;
}
