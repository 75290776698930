$FONT_PRIMARY: Inter, sans-serif !important;
$FONT_SECONDARY: Dancing Script, cursive !important;
$HEADER_FONT_PRIMARY: NoiGrotesk, sans-serif !important;
$base-font-size: 16; // Base font-size in pixels.

$screen-sm-max: 600px;
$screen-md-min: 600px;
$screen-md-max: 900px;
$screen-lg-min: 900px;

@use "sass:math";
@use "colors" as c;

@function pxtorem($px) {
  @return #{math.div($px, $base-font-size)}rem;
}

@mixin font-setting($font-family, $font-size, $font-weight, $letter-spacing, $line-height, $color) {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
  line-height: $line-height;
  color: $color;
}

@media (max-width: $screen-sm-max) {
  .title1 {
    @include font-setting($HEADER_FONT_PRIMARY, pxtorem(24), 500, pxtorem(-0.48px), pxtorem(32), c.$dark-grey);
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .title1 {
    @include font-setting($HEADER_FONT_PRIMARY, pxtorem(36), 500, pxtorem(-0.5), pxtorem(40), c.$dark-grey);
  }
}

@media (min-width: $screen-lg-min) {
  .title1 {
    @include font-setting($HEADER_FONT_PRIMARY, pxtorem(36), 500, pxtorem(-0.5), pxtorem(40), c.$dark-grey);
  }
}

.title2 {
  @include font-setting($HEADER_FONT_PRIMARY, pxtorem(24), 500, pxtorem(-0.5), pxtorem(30), c.$dark-grey);
}

.title3 {
  @include font-setting($HEADER_FONT_PRIMARY, pxtorem(18), 500, pxtorem(-0.5), pxtorem(25), c.$dark-grey);
}

.title4 {
  @include font-setting($FONT_PRIMARY, pxtorem(14), 500, pxtorem(-0.5), pxtorem(20), c.$dark-grey);
}

.title5 {
  @include font-setting($FONT_PRIMARY, pxtorem(11), 500, pxtorem(-0.5), pxtorem(15), c.$dark-grey);
}

.bodyLarge {
  @include font-setting($FONT_PRIMARY, pxtorem(18), 300, pxtorem(-1), pxtorem(25), c.$dark-grey);
}

.caption {
  @include font-setting($FONT_PRIMARY, pxtorem(14), 300, pxtorem(-0.4), pxtorem(20), c.$dark-grey);
}

.caption2 {
  @include font-setting($FONT_PRIMARY, pxtorem(14), 300, pxtorem(-0.4), pxtorem(15), c.$dark-grey);
}

.captionBold {
  @include font-setting($FONT_PRIMARY, pxtorem(14), 500, pxtorem(-0.3), pxtorem(15), c.$dark-grey);
}

.captionSmall {
  @include font-setting($FONT_PRIMARY, pxtorem(11), 400, pxtorem(-0.3), pxtorem(15), c.$dark-grey);
}

.captionSmall2 {
  @include font-setting($FONT_PRIMARY, pxtorem(12), 400, pxtorem(-0.3), pxtorem(16), c.$dark-grey);
}

.bodyTiny {
  @include font-setting($FONT_PRIMARY, pxtorem(8), 400, pxtorem(-0.1), pxtorem(10), c.$dark-grey);
}
