.utp-progress-bar {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: radial-gradient(closest-side, #f6f3ef 88%, transparent 80% 100%), conic-gradient(#e2dfdc 75%, #f1ede8 0);
  overflow: hidden;
  -webkit-animation: spin 3s linear infinite;
  /* Safari */
  animation: spin 3s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
