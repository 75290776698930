.view-item-box {
    height: 55px;
}

.view-item-padding {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.view-item-padding:hover {
    pointer-events: auto;
    cursor: pointer;
    background-color: #F6F3EF;
}

.view-item-padding:focus {
    pointer-events: auto;
    cursor: pointer;
    background-color: #EEE9E3;
}

.view-item-padding-active {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    pointer-events: auto;
    cursor: pointer;
    background-color: #F6F3EF;
}


.view-item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.view-item-detail {
    display: flex;
    align-items: center;
    gap: 10px;
}

.item-title-detail {
    display: flex;
    flex-direction: column;
}

.loader-padding {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}