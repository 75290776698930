.cw-table {
  display: table;
  border-collapse: collapse;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border-spacing: 0px !important;
  width: 100%;
}

.cw-table-data {
  width: 30px;
  height: 23px;
}

.cw-table-head {
  width: 30px;
  height: 18px;
}

.cw-event-list {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-bottom: 3px;
}

.cs-calendar-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  height: 100%;
  max-height: 280px;
  width: 100%;
}

.cs-separation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
}

// UserCalendarLoader
.ucw-loader-container {
  width: 100%;
  height: 5px;
  background-color: #f1ede8;
  border-radius: 3px;
  overflow: hidden;
}

.ucw-progress {
  width: 95%;
  height: 100%;
  background-color: #e2dfdc;
  transition: width 2s ease;
  animation: shine 2s infinite linear;
  background: linear-gradient(to left, transparent 0%, #f5f5f5 50%, transparent 70%);
  background-size: 200%;
}

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 200%;
  }
}


.event-short-button {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: 0px 2px 20px 0px #0d0d0e1a;
}

.event-short-button:hover {
  cursor: pointer;
}