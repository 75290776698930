.button {
  text-transform: inherit !important;
}

.MuiButton-root
  .MuiButton-contained
  .MuiButton-containedPrimary
  .MuiButton-sizeMedium
  .MuiButton-containedSizeMedium
  .MuiButtonBase-root {
  text-transform: inherit !important;
}

.MuiTypography-root {
  font-family: Inter, sans-serif !important;
}

#root {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  overflow-y: hidden;
}

body {
  margin: 0px;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: Inter, sans-serif !important;
}

.fadeIn-delay {
  animation: fadeIn 0.5s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
