.document-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 62px);
}

.document-editor-container {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 122px);
  background-color: white;
}

.tiptap-document {
  :first-child {
    height: '100%';
  }
  p {
    margin: 0px;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 0;
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  .smart-field-custom-marker {
    background-color: rgb(255, 206, 0);
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    cursor: grab;
    hover {
      cursor: grab;
    }
    active {
      cursor: grabbing;
    }
  }

  .smart-field-custom-marker-readonly {
    background-color: rgb(255, 206, 0);
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .smart-field-custom-marker-completed {
    background-color: rgb(252, 228, 132);
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .smart-field-custom-marker-sign {
    background-color: rgb(255, 206, 0);
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    cursor: pointer;
    color: rgb(159, 2, 2);
    opacity: 0.8;
  }

  .smart-field-custom-marker-signing {
    font-family: cursive;
  }

  /* .smart-field-custom-marker:hover {
    cursor: grab;
  }
  .smart-field-custom-marker:active {
    cursor: grabbing;
  }

  .smart-field-custom-marker:active {
    cursor: grabbing;
  } */

  /* Code and preformatted text styles */
  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }

  /* Table-specific styling */
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;
    margin-top: 0;

    td,
    th {
      border: 1px solid black;
      box-sizing: border-box;
      /* min-width: 1em; */
      /* padding: 6px 8px; */
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: white;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: lightgray;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: purple;
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }

    .row-resize-handle {
      background-color: purple;
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  .tableWrapper {
    overflow-x: auto;
  }

  .image-resizer {
    display: inline-flex;
    position: relative;
    flex-grow: 0;
  }
  .image-resizer .resize-trigger {
    position: absolute;
    right: -6px;
    bottom: -9px;
    opacity: 0;
    transition: opacity 0.3s ease;
    color: #3259a5;
  }
  .image-resizer:hover .resize-trigger {
    opacity: 1;
  }
}

.ProseMirror-document {
  width: 793.7007874px;
  padding: 96px;
  background-color: white;
}

.ProseMirror-gapcursor {
  cursor: pointer;
}

.ProseMirror-selectednode {
  div {
    margin: 15px;
  }
}

.smart-field-custom-marker {
  background-color: rgb(255, 206, 0);
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: grab;
  hover {
    cursor: grab;
  }
  active {
    cursor: grabbing;
  }
}

.smart-field-custom-marker-readonly {
  background-color: rgb(255, 206, 0);
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.smart-field-custom-marker-completed {
  background-color: rgb(252, 228, 132);
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.smart-field-custom-marker-sign {
  background-color: rgb(255, 206, 0);
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}

/* .smart-field-custom-marker:hover {
  cursor: grab;
}
.smart-field-custom-marker:active {
  cursor: grabbing;
}

.smart-field-custom-marker:active {
  cursor: grabbing;
} */
