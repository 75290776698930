$base-font-size: 16; // Base font-size in pixels.

@use "sass:math";
@use "../abstract/typography.scss" as t;
@use "../abstract/colors.scss" as c;

@function pxtorem($px) {
  @return #{math.div($px, $base-font-size)}rem;
}

.pagination-input {
  max-width: pxtorem(45);
  width: 100%;
  height: pxtorem(26);
  border: none;
  outline: none;
  border-radius: pxtorem(8);
  padding: 0px;
  text-align: center;
  @extend .caption;
}

.pagination-input::-webkit-outer-spin-button,
.pagination-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pagination-strip {
  display: flex;
  justify-content: flex-start;
  background: c.$white;
  align-items: center;
  padding-top: pxtorem(8);
  padding-bottom: pxtorem(8);
  height: pxtorem(32);
  border-top: 1px solid c.$grey-middle;
}

.pagination-strip:hover {
  border-top: 1px solid c.$grey-middle;
}

.pagination-sticky-strip {
  @extend .pagination-strip;
  position: sticky;
  bottom: pxtorem(-40);
  z-index: 2;
}
