.upl-wave {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #e2dfdc;
  overflow: hidden;
  background: linear-gradient(to right, #e2dfdc 0%, #f5f5f5 50%, #e2dfdc 70%);
  background-size: 200% 100%;
  animation: circularshine 2s infinite linear;
}

@keyframes circularshine {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

.upl-loader-container {
  width: 90px;
  height: 5px;
  background-color: #f1ede8;
  border-radius: 3px;
  overflow: hidden;
}

.upl-progress {
  width: 0;
  height: 100%;
  background-color: #e2dfdc;
  transition: width 1s ease;
  animation: shine 2s infinite linear;
  background: linear-gradient(to right, transparent 0%, #f5f5f5 50%, transparent 70%);
  background-size: 200%;
}

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 200%;
  }
}
