.data-row-entry {
  border-top: 0.5px solid #f5f5f5;
  border-bottom: 0.5px solid #f5f5f5;
  border-right: 0.1px solid #f5f5f5;
  vertical-align: top;
  padding-left: 0;
  padding-right: 0;
  padding: 0;
  box-sizing: border-box;
}

.data-row-entry:hover {
  border-top: 0.5px solid #f5f5f5;
  border-bottom: 0.5px solid #f5f5f5;
  border-right: 0.1px solid #f5f5f5;
}

.data-row-user {
  border-right: 0.5px solid #f5f5f5;
  border-top: 0.5px solid #f5f5f5;
  border-bottom: 0.5px solid #f5f5f5;
  vertical-align: top;
  box-sizing: border-box;
  max-width: 130px;
  min-width: 130px;
  width: 130px;
  padding: 0;
}

.data-row-user:hover {
  border-right: 0.5px solid #f5f5f5;
  border-top: 0.5px solid #f5f5f5;
  border-bottom: 0.5px solid #f5f5f5;
}

.user-name-avatar {
  display: flex;
  align-items: center;
  height: 48px;
}

.styled-th {
  box-sizing: border-box;
}

.timeline-days-styled-th {
  box-sizing: border-box;
}

.holiday-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  text-align: center;
}

.holiday-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f5f5f5;
  padding: 5px 3px;
  border-radius: 8px;
}

.textOverflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
