.base-structure {
  border-radius: 20px;
  padding: 20px;
  height: 240px;
  position: relative;
  background-color: rgba(246, 243, 239, 0.7);
}

.base-structure:hover {
  transform: scale(1.02);
}

.read-structure {
  background-color: #f6f3ef;
  border-radius: 20px;
  padding: 20px;
  height: 240px;
  position: relative;
}

.large-width {
  width: 550px;
}

.small-width {
  width: 240px;
}

.widget-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.slant-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f1ede8;
  clip-path: polygon(40% 0, 100% 0, 100% 100%, 0 100%);
  transition: clip-path 1s linear;
  border-radius: 20px;
}

.slant-overlay.active {
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%);
  animation: slantAnimation 1s linear infinite;
}

@keyframes slantAnimation {
  0% {
    clip-path: polygon(40% 0, 100% 0, 100% 100%, 0 100%);
  }

  50% {
    clip-path: polygon(60% 0, 100% 0, 100% 100%, 0 100%);
  }

  100% {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%);
  }
}

.react-grid-item.react-grid-placeholder {
  background-color: transparent !important;
  background: transparent !important;
  border: 2px dashed #f6f3ef !important;
}

@media (min-width: 900px) {
  .grid-width {
    width: 960px;
  }
}

@media (min-width: 800px) and (max-width: 900px) {
  .grid-width {
    width: 700px;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .grid-width {
    width: 600px;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
  .grid-width {
    width: 500px;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
  .grid-width {
    width: 400px;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .grid-width {
    width: 300px;
  }
}
