.heatmap-td {
  height: 60px;
  width: 60px;
  max-width: 60px;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  pointer-events: auto;
}

.heatmap-noevent-td {
  height: 60px;
  width: 60px;
  max-width: 60px;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
  cursor: default;
  pointer-events: none;
}

.heatmap-td:hover {
  opacity: 0.7;
}
