.no-hover-effect:hover {
  color: #2f2f2f;
  background-color: #f6f3ef;
  border-radius: 8px;
}

.hover-effect:hover {
  color: #2f2f2f;
  background: #fbfaf9;
  border-radius: 8px;
}
