$base-font-size: 16; // Base font-size in pixels.

@use "sass:math";

@function pxtorem($px) {
  @return #{math.div($px, $base-font-size)}rem;
}

.main-title {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.sub-title {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}
