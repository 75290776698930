// PRIMARY COLORS

$red-dark: #b90f0f;
$red-hover: #b90f0f74;
$yellow-zelt: #ffcd6d;
$yellow-hover: #fac256;
$yellow-press: #f3ba4c;
$yellow-disable: #ffcd6d;
$dark-grey: #2f2f2f;
$border-grey: #f1eeea;
$background: #f6f3ef;
$background-light: #fbfaf9;
$grey: #9e9e9e;
$grey-middle: #e2dfdc;
$grey-hover: #f1ede8;
$grey-press: #eee9e3;
$orange: #ff9664;
$red: #eb5a4b;
$green: #74cf8c;
$white: #ffffff;
$brown: #6d5144;
