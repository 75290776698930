@use "../abstract/colors.scss" as c;


.switch-hover:hover {
  background: #f1ede8;
  cursor: pointer;
}

.switch-no-hover:hover {
  background: #2f2f2f;
  cursor: pointer;
}


.tnm-switch-text {
  color: c.$dark-grey;
}

.tnm-switch-active-text {
  color: c.$white;
}