.clickable {
  cursor: pointer;
}

.todo-item:hover {
  background-color: #0000001a;
}

// UserTodosVersionBigLoading && UserTodosVersionSmallLoading
.uai-loader-container {
  width: 100%;
  height: 5px;
  background-color: #f1ede8;
  border-radius: 3px;
  overflow: hidden;
}

.uai-progress {
  width: 95%;
  height: 100%;
  background-color: #e2dfdc;
  transition: width 2s ease-in-out;
  animation: shine 2s infinite linear;
  background: linear-gradient(to left, transparent 0%, #f5f5f5 50%, transparent 70%);
  background-size: 200%;
}

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 200%;
  }
}
