.otp-style {
  border: none;
  border-image: none;
  box-shadow: none;
  transition: none;
  min-width: 30px;
}

:hover {
  border: none;
  border-image: none;
}

:focus {
  border: none;
  border-image: none;
}

::after {
  border: none;
  border-image: none;
}

:active {
  border: none;
  border-image: none;
}

input[type='text'] {
  border: none;
}

input[type='text']:focus {
  border: none;
}

input.middle:focus {
  outline-width: 0;
}

*:focus {
  outline: none;
}
