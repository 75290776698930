/* This CSS class is for the parent container */
.user-cell-container:hover .preview-button {
  display: flex; /* or 'block', depending on your needs */
}

/* This CSS class is to initially hide the button */
.preview-button {
  display: none;
  margin-left: auto; /* Align-right */
}
