.progress-bar {
  background-color: #f6f3ef;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  width: 100%;
}

.progress-border-red {
  border: 0.001px solid #eb5a4b;
}

.progress-no-border {
  border: 0.001px solid #f6f3ef;
}

.progress-bar-fill {
  background-color: #328ce7;
  height: 4px;
  border-radius: 8px;
  transition: width 0.5s ease-in-out;

  &:hover {
    height: 6px;
  }
}

.progress-bar-blue {
  background-color: #328ce7 !important;
}

.progress-bar-green {
  background-color: #74cf8c !important;
}

.progress-bar-red {
  background-color: #eb5a4b !important;
}

.progress-bar-percentage {
  text-align: right;
  margin-top: 5px;
}
